/* Leading Paragraph with Bullet */
/* .textEditor .text ul {
    font-size: var(--step-1);

    & p {
        font-size: var(--step-1);
    }
} */

/* Text With Bullets */

.textEditor a {
    @apply underline;
    text-decoration-color: var(--color-brand-secondary);
    text-decoration-thickness: 2px;
    text-underline-offset: 4px;
    transition-property: color, border-color, opacity, background-color;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
}

.textEditor a:hover {
    text-decoration-color: var(--color-brand-teal);
}

.textEditor ol{
    list-style: decimal;
    padding-left:1.3rem;
}

.textEditor .text {
    max-width: 100%;
}

.textEditor .text li {
    display: flex;
}

.textEditor .text ol li{
    display: list-item;
    padding-left:21px;
}

.textEditor .text ol li::before{
    display: none;
}

.textEditor .text li p {
    margin-bottom: var(--space-s);
}

.textEditor .text li p.lead-text {
    margin-bottom: var(--space-m);
}

.textEditor .text li:last-of-type p {
    margin-bottom: 0;
}

.textEditor .text h3,
.textEditor .text h4,
.textEditor .text h5,
.textEditor .text h6 {
    margin-top: var(--space-2xl);
}

.textEditor .lead-text {
    font-size: var(--step-1);
}

.textEditor .btn {
    display: table;
}

.textEditor iframe,
.textEditor .text img,
.textEditor .text blockquote {
    margin-top: var(--space-2xl);
    margin-bottom: var(--space-2xl);
}

.textEditor .btn + .btn {
    margin-top: calc(((var(--space-xl)) * -1) + var(--space-xs));
}

.textEditor .text li::before {
    content: "";
    background-image: url(../../../svg/bullet.svg);
    background-repeat: no-repeat;
    width: 21px;
    height: 21px;
    padding-right: var(--space-l);
    top: 9px;
    position: relative;
}

/* Top Level Paragraph */

@screen md {
    .textEditor .text > p {
        max-width: 80ch;
    }
}

/* Blockquote */
.textEditor .text blockquote {
    @apply border-brand-secondary border-l-2;
    padding-left: var(--space-m);
    padding-bottom: var(--space-xs);

    & p {
        @apply text-brand-teal;
        font-size: var(--step-1);
        line-height: 1.5;
        font-weight: 400;
    }
    & p:last-child {
        font-size: var(--step-0);
        padding-top: var(--space-s);
    }
}

.has-dark-background .textEditor .text blockquote{
    @apply  border-brand-teal;
    & p {
        @apply text-brand-sage;
    }
}

/* Margin between texteditor html elements */
.textEditor .text > *,
.textEditor > * {
    margin-bottom: var(--space-xl);
}

.textEditor .text > .lead-text {
    margin-bottom: var(--space-xl);
    font-size: var(--step-1);
}

.textEditor > *:last-child,
.textEditor .text > *:last-child {
    margin-bottom: 0;
}
