.nav-wrapper {
    @apply w-full border-brand-forest border-b border-opacity-50;
}

.nav-container {
    @apply mx-auto flex flex-nowrap justify-between items-center;
    padding: var(--space-3xs) var(--space-s-xl);
    max-width: 1540px;
}

.nav__menu {
    z-index: 51;
    position: relative;
    left: -2px;
    outline: none;
}

.nav__menu g {
    top: 50%;
    transform: translateY(18px) translateX(2px);
}

.nav__logo {
    max-width: 240px;
    & svg {
        width: 100%;
    }
}

.nav__contact-cta {
    z-index: 51;
    @apply flex justify-end hidden;

}

.nav-list {
    @apply xl:flex xl:items-center;
    gap: var(--space-m);
}

.nav__bottom-logo {
    @apply hidden;
}
.js-hamburger-menu {
    transition-duration: 1s;
    outline: none;
}
.js-hamburger-menu__text {
    position: relative;
    top: -12px;
}
.js-hamburger-menu svg rect {
    transition-property: transform;
    transition-duration: 500ms !important;
    transform: scaleX(1);
}

.js-hamburger-menu svg rect:last-child {
    transform-origin: left;
}

.js-hamburger-menu:hover svg,
.js-hamburger-menu:focus svg {
    & rect {
        transition-duration: 500ms;
    }

    & rect:nth-child(1) {
        transform: scaleX(1.2);
    }
    & rect:nth-child(2) {
        transition-property: transform, opacity;
        transform: scaleX(1.08);
    }
    & rect:nth-child(3) {
        transform: scaleX(1.2);
    }
}

/* Menu toggle ttransition */
.fullpage-nav-open .nav__menu:hover rect:first-child,
.fullpage-nav-open .nav__menu:focus rect:first-child,
.fullpage-nav-open .nav__menu svg rect:first-child {
    transform: rotate(30deg) scaleX(0.96);
    transform-origin: 0 0;
}
.fullpage-nav-open .nav__menu:hover svg rect:nth-child(2),
.fullpage-nav-open .nav__menu:focus svg rect:nth-child(2),
.fullpage-nav-open .nav__menu svg rect:nth-child(2) {
    transform: scaleX(0);
    opacity: 0;
}
.fullpage-nav-open .nav__menu:hover svg rect:last-child,
.fullpage-nav-open .nav__menu:focus svg rect:last-child,
.fullpage-nav-open .nav__menu svg rect:last-child {
    transform-origin: 0 22px;
    transform: rotate(-30deg) scaleX(0.96);
}

.nav-link {
    @apply text-left flex mr-auto;
    font-size: var(--step-2);
    line-height: 1.2;
    position: relative;
    
    &:hover,
    &:focus, {
        @apply text-white;
        text-decoration-color: #FFF;
    }
}

.nav-link--active {
    &::after {
    }
}

.fullpage-nav {
    @apply text-white lg:text-lg bg-brand-secondary h-screen flex flex-col;
    max-width: 100vw;
}

.fullpage-nav-container {
    @apply w-full h-full relative flex flex-row justify-between;
    align-items: center;
    gap: var(--space-xs);
    padding: var(--space-xl) var(--space-xs-2xl) var(--space-xl);
    max-width: 1540px;
    max-height: 100vh;
    margin-left: auto;
    margin-right: auto; 
}

.fullpage-nav-open  .nav__contact-cta {
    @apply block !important;
}
.fullpage-nav-open .fullpage-nav {
    /* @apply transition ease-out duration-200 transform origin-top-right; */
    @apply opacity-100 scale-100 fixed top-0 inset-x-0 z-50;
}

.fullpage-nav-closed .fullpage-nav {
    @apply opacity-0 scale-95 absolute inset-x-0 p-2 transition transform origin-top-right;
    top: -100vh;
}

.fullpage-nav-links {
    @apply flex flex-col;
    gap: var(--space-xs);
}

.dark-menu {
    @apply bg-brand-forest text-brand-sage;
    &.nav-wrapper {
        @apply border-brand-sage-accent border-opacity-50;
    }
}

.fullpage-nav-open .nav__menu rect {
    fill: white;
}

.fullpage-nav-open .btn {
    @apply border-brand-sage-accent text-white;
}

@screen md {
    .nav-container {
        padding: var(--space-xs) var(--space-s-xl);
    }

    .nav__logo {
        @apply absolute;
        width: 348.66px;
        max-width: unset;
        height: 21px;
        top: 50%;
        left: 0;
        right: 0;
        margin: auto;
        transform: translateY(-50%);
        transform: translateY(-50%) translateX(-50%);
        text-align: center;
    }

    .nav__bottom-logo {
        @apply block;
        height: auto;
        width: 40%;
        margin-bottom: 8px;
    }

    .fullpage-nav-container {
        align-items: flex-end;
        padding: var(--space-2xl) var(--space-xl) var(--space-2xl);
    }
}

@screen lg {
    .nav-link {
        font-size: var(--step-3);
    }

    .nav__bottom-logo {
        @apply block;
        height: 84px;
        width: 395px;
        margin-bottom: var(--space-xs);
    }

    .nav__contact-cta {
        @apply block;
    }

    .fullpage-nav-container {
        padding: var(--space-2xl) var(--space-xl) var(--space-xl);
    }
}

@screen xl {
    .fullpage-nav-closed .fullpage-nav {
        @apply hidden;
    }
}

@screen xxl {
    .nav-container {
        max-width: 1680px;
    }
    .fullpage-nav-container {
        max-width: 1680px;
    }
}