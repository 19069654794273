
:root {
  /* Spacing */
  /* @link https://utopia.fyi/space/calculator/?c=320,18,1.2,1140,21,1.414,5,2,&s=0.75|0.5|0.25,1.5|2|3|4|6|8,s-l|l-2xl */
  --space-3xs: clamp(0.31rem, calc(0.31rem + 0vw), 0.31rem);
  --space-2xs: clamp(0.56rem, calc(0.51rem + 0.24vw), 0.69rem);
  --space-xs: clamp(0.88rem, calc(0.83rem + 0.24vw), 1rem);
  --space-s: clamp(1.13rem, calc(1.05rem + 0.37vw), 1.31rem);
  --space-m: clamp(1.69rem, calc(1.57rem + 0.61vw), 2rem);
  --space-l: clamp(2.25rem, calc(2.1rem + 0.73vw), 2.63rem);
  --space-xl: clamp(3.38rem, calc(3.16rem + 1.1vw), 3.94rem);
  --space-2xl: clamp(4.5rem, calc(4.21rem + 1.46vw), 5.25rem);
  --space-3xl: clamp(6.75rem, calc(6.31rem + 2.2vw), 7.88rem);
  --space-4xl: clamp(9rem, calc(8.41rem + 2.93vw), 10.5rem);

  /* One-up pairs */
  --space-3xs-2xs: clamp(0.31rem, calc(0.17rem + 0.73vw), 0.69rem);
  --space-2xs-xs: clamp(0.56rem, calc(0.39rem + 0.85vw), 1rem);
  --space-xs-s: clamp(0.88rem, calc(0.7rem + 0.85vw), 1.31rem);
  --space-s-m: clamp(1.13rem, calc(0.78rem + 1.71vw), 2rem);
  --space-m-l: clamp(1.69rem, calc(1.32rem + 1.83vw), 2.63rem);
  --space-l-xl: clamp(2.25rem, calc(1.59rem + 3.29vw), 3.94rem);
  --space-xl-2xl: clamp(3.38rem, calc(2.64rem + 3.66vw), 5.25rem);
  --space-2xl-3xl: clamp(4.5rem, calc(3.18rem + 6.59vw), 7.88rem);
  --space-3xl-4xl: clamp(6.75rem, calc(5.29rem + 7.32vw), 10.5rem);

  /* Custom pairs */
  --space-xs-2xl: clamp(0.88rem, calc(-0.83rem + 8.54vw), 5.25rem);
  --space-xs-3xl: clamp(0.88rem, calc(-1.86rem + 13.66vw), 7.88rem);
  --space-s-l: clamp(1.13rem, calc(0.54rem + 2.93vw), 2.63rem);
  --space-s-xl: clamp(1.13rem, calc(0.03rem + 5.49vw), 3.94rem);
  --space-m-xl: clamp(1.69rem, calc(0.81rem + 4.39vw), 3.94rem);
  --space-m-2xl: clamp(1.69rem, calc(0.30rem + 6.95vw), 5.25rem);
  --space-xl-3xl: clamp(3.38rem, calc(1.62rem + 8.78vw), 7.88rem);
  --space-2xl-4xl: clamp(4.50rem, calc(2.16rem + 11.71vw), 10.50rem);
  --space-l-2xl: clamp(2.25rem, calc(1.08rem + 5.85vw), 5.25rem);

  /* Inner Padding */
  --padding-block: var(--space-xs-s) var(--space-s-m);
}
