.profile {
    @apply border-t border-brand-forest border-opacity-50 my-10 py-10;
}

.has-dark-background .profile {
    @apply border-brand-teal;
}

.profile + .profile {
    @apply -mt-10;
}

.profile__title-wrapper {
    @apply flex justify-between;
}

.profile h4 {
    @apply mb-10;
}

.profile__image-mobile {
    @apply md:hidden float-right;
    border-radius: 21px;
}

.profile__grid {
    @apply flex flex-wrap items-start;
    gap: var(--space-l);
    & > div {
        @apply w-full;
    }
}

.profile__text {
    @apply flex flex-col items-start;
    @apply w-2/3;
    gap: var(--space-m-2xl);

    & img {
        min-width: 157px;
    }
}

.profile__image {
    @apply w-1/3;
    & .profile__image-desktop {
        @apply w-full hidden md:block;
        border-radius: 21px;
    }
}

@screen md {
    .profile__grid {
        & > .profile__text {
            width: calc(66.66% - (var(--space-l) / 2));
        }

        & > .profile__image {
            width: calc(33.33% - (var(--space-l) / 2));
        }
    }
}
