.btn {
    @apply relative flex justify-center  border-brand-forest border-2;
    border-radius: 500px;
    cursor: pointer;
    text-align: center;
    overflow: hidden;
    padding: 6px var(--space-xl) 4px;
    font-size: 18px;
    letter-spacing: -0.01em;
    text-decoration: none !important;
}

.btn:hover,
.btn:focus {
    @apply border-brand-secondary;
    background: rgba(0, 71, 80, 0.03);
}

.btn-flex {
    @apply flex flex-col items-center;
}

section .btn{
    @apply border-brand-secondary px-14 w-full inline-flex;
}

.dark-menu .btn {
    @apply text-brand-sage border-brand-sage;
}

.dark-menu .btn:hover,
.dark-menu .btn:focus {
    background: rgba(0, 71, 80, 0.18);
}

.fullpage-nav-open .dark-menu .btn:hover,
.fullpage-nav-open .dark-menu .btn:focus {
    background: rgba(0, 71, 80, 0.03);
}

@screen xs {
    section .btn {
        @apply w-auto;
    }
}

button.js-hamburger-menu {
    transition: unset;
}