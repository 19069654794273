.section-content-container.latest-articles-content-container {
    padding-left: 0;
    padding-right: 0;
    max-width: none;
}

.latest-articles-section-container .latest-articles-content-container {
    padding-bottom: 0;
}

.blog-posts-inner-wrapper {
    max-width: 1380px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 0;
    padding-bottom: var(--space-2xl-4xl);
    padding-left: var(--space-s-xl);
    padding-right: var(--space-s-xl);
}
