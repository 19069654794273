input,
select {
    @apply bg-white pl-4 w-full outline-none;
    border-bottom: 1px solid;
}

select {
    appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='14' viewBox='0 4.5 24 15'%3E%3Cpath d='M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position-x: 97%;
    background-position-y: 34px;
}

textarea {
    @apply bg-white pl-4 w-full outline-none;
    min-height: 240px;
    border-bottom: 1px solid;
}


input:focus,
select:focus,
textarea:focus {
    border-bottom: 1px solid var(--color-brand-secondary);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #fcf8f1 inset !important;
}
