.social-links {
    display: flex;
    align-items: end;
}

.social-links ul {
    justify-content: flex-start;
}

.social-links ul li {
    margin-right: var(--space-m);
}

.social-links path {
    @apply transition-colors;
    fill: var(--color-brand-sage);
}

.social-links li:hover path,
.social-links li:focus path {
    fill: var(--color-brand-secondary);
}
