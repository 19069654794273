
/* a {
    text-decoration: underline;
    text-decoration-color: var(--color-brand-secondary);
} */


.anchor {
    @apply underline;
    text-decoration-color: transparent;
    text-decoration-thickness: 2px;
    text-underline-offset: 4px;
    transition-property: color, border-color;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
}

.anchor--primary {
    @apply text-brand-primary transition-colors hover:text-brand-secondary;
}

.anchor--secondary {
    @apply text-brand-secondary transition-colors hover:text-brand-primary;
}

.anchor:hover,
.anchor:focus {
    text-decoration-color: var(--color-brand-secondary);
}
