.cards__container {
    @apply flex flex-wrap justify-center;
    gap: var(--space-s-m);
}

.card {
    @apply bg-white h-full;

    & .card__heading {
        @apply text-brand-primary font-bold text-center;
    }
}

.card__link {
    @apply text-brand-primary font-bold;
}

.card__content {
    padding: var(--padding-block);

    & h3 {
        @apply text-brand-secondary font-bold;
    }
}

@screen md {
    .card {
        max-width: calc(50% - (var(--space-s-m) / 2));
    }
}
