.services-list {
    @apply flex flex-wrap w-full;
    gap: var(--space-l);
}

.services-item {
    @apply border-t border-brand-forest border-opacity-50;
    position: relative;
}

.services-item__title {
    display: flex;
    flex-wrap: nowrap;
    gap: var(--space-2xs);
    margin: var(--space-l) 0 var(--space-m);

    .label__number {
        margin: 0;
        width: 27px;
        height: 27px;
    }
}

.services-item-link {
    @apply flex items-center;
    margin-top: var(--space-m);
}

.services-item-link span.service-item-link-icon {
    @apply text-brand-secondary;
    margin-right: var(--space-s);
}

.services-section-container .lead-text {
    margin-top: var(--space-l);
}

.services-section-container img {
    width: 900px;
}

.services-section-container img,
.services-list {
    margin-top: var(--space-2xl);
}

@screen xl {
    .services-item__title { 
        .label {
            position: relative;
            top: 2px;
        }
    }
    .services-list {
        & > div {
            flex-grow: 3;
            width: calc(33.33% - ((var(--space-l) * 2) / 3));
        }
    }
}
