.section-container {
    @apply w-full;
}

.section-content-container {
    @apply mx-auto flex flex-wrap justify-between lg:flex-nowrap;
    max-width: 1380px;
    gap: var(--space-2xl);
    padding-top: var(--space-2xl-4xl);
    padding-bottom: var(--space-2xl-4xl);
    padding-left: var(--space-s-xl);
    padding-right: var(--space-s-xl);
}

/* Fix margin between successive section elements of same background colour */
.section-container:not(.has-dark-background):not(:first-of-type) + .section-container:not(.has-dark-background) .section-content-container:not(.subscribe-content-container),
.section-container.has-dark-background:not(:first-of-type) + .section-container.has-dark-background .section-content-container:not(.subscribe-content-container) {
    padding-top: 0;
}

.image_aside-content-container {
    @apply justify-center;
}

.section-content-container > * {
    max-width: 100%;
}

.section-content-container.banner-content-container {
    padding: 0;
    max-width: 100%;
}


.style-2 .hero-content-container {
    max-width:1540px;
}

.article-hero-section-container {
    @apply bg-brand-forest text-brand-sage;
}


.article-text-content-container {
    padding-top: var(--space-3xl);
}

.featured-articles-hero-section-container {
    @apply bg-brand-forest text-brand-sage;
}

.featured-articles-hero-content-container {
    @apply flex items-center;
}

.featured-articles-tags-content-container {
    @apply flex justify-start py-0;
}

.section-content-container.featured-articles-content-container {
    padding-bottom: var(--space-2xl-3xl);
}

.featured-articles-tags-section-container
    + .latest-articles-section-container
    .latest-articles-content-container,
.hero-section-container
    + .latest-articles-section-container
    .latest-articles-content-container {
    padding-top: var(--space-2xl);
}

.services-content-container {
    flex-wrap: wrap;
    gap: 0;
}

.hero-section-container {
    @apply border-brand-forest border-opacity-50 border-b;
}


.article-hero-content-container {
    @apply pb-0;
    padding-top: var(--space-xl-3xl);
}

.article-text-content-container{
    padding-top: calc(var(--space-2xl-3xl) + var(--space-xl));
}

@screen lg {
    .image_aside-content-container {
        @apply justify-between;
    }
}

@screen xxl {
    .style-2 .hero-content-container {
        max-width: 1680px;
    }
}