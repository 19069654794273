.textEditor .accordion__container{
    @apply my-10;
}

.accordion__container {
    @apply w-full;
}

.accordion__title {
    @apply font-sans;
}

.accordion__header {
    @apply flex justify-between cursor-pointer;
    padding: var(--space-s-m) 0;
}

.accordion__icon {
    height: max-content;
    transition: 0.3s ease;
}

.accordion__item {
    position: relative;
    transition: 0.25s ease;
    border-bottom: 1px solid var(--color-brand-secondary);
}

.accordion__item:first-child {
    border-top: 1px solid var(--color-brand-secondary);
}

.accordion__content-container {
    @apply overflow-hidden h-0;
    transition: 0.25s ease;
}

.accordion__content {
    @apply px-10 py-6 lg:px-20 lg:py-12;
    padding: var(--space-xs-s)  var(--space-s-m) var(--space-xs-s) var(--space-xl);
}

.accordion__icon{
    & rect{
        stroke: var(--color-brand-teal);
    }
    & path{
        fill: var(--color-brand-teal);
    }
}

.accordion-open .accordion__icon {
    transform: rotate(180deg);
}
