.articles__featured {
    @apply flex flex-wrap w-full;
    gap: var(--space-l);
    row-gap: var(--space-2xl);
    & > div {
        width: 100%;
    }
}

.articles__featured__item img {
    @apply w-full;
    border-radius: 21px;
}
.articles__featured__item__text {
    position: relative;
}
.articles__featured__item__text h4 {
    margin-bottom: var(--space-s);
}
.article__featured_label {
    @apply absolute right-0;
    top: calc(var(--space-2xs) - (var(--space-2xs)/4));
}

.articles__featured .article__date {
    margin-bottom: var(--space-l);
}

.section-content-container.featured-learning-and-discovery-items-content-container,
.section-content-container.featured-re-sources-items-content-container {
    padding-bottom: var(--space-2xl-3xl);
}

.hero-section-container + .latest-articles-section-container > .section-content-container.latest-articles-content-container,
.hero-section-container + .latest-articles-section-container > .section-content-container.latest-articles-content-container {
    padding-top: var(--space-2xl-4xl);
}

@screen md {
    .articles__featured__item {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: var(--space-l);

        &:nth-child(odd) .articles__featured__item__image {
            order: 2;
        }
        &:nth-child(odd) .articles__featured__item__text {
            order: 1;
        }
        & .articles__featured__item__image {
            width: calc(40% - (var(--space-l) / 2));
        }
        & .articles__featured__item__text {
            width: calc(60% - (var(--space-l) / 2));
        }
    }
    .articles__featured__item > div {
        width: calc(50% - (var(--space-l) / 2));
    }
    .articles__featured .article__date {
        padding-top: 0;
    }
    .article__featured_label {
        top: calc((var(--space-2xs)/4) * -1);
    }
}
@screen xl {
    .articles__featured__item__text h4 {
        font-size: var(--step-3);
    }
}
