.label {
    @apply flex uppercase font-bold text-brand-teal;
    letter-spacing: 1px;
    font-size: var(--label-text);
}

.label__text {
    @apply border border-brand-teal flex items-center px-5;
    line-height: 23px;
    border-radius: 50px;
    padding-top: 2px;
    white-space: nowrap;
    transition-property: color, background-color;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
}

a.label:hover .label__text {
    &:hover {
        @apply bg-brand-teal text-white;
    }
}
a.label:focus .label__text {
    &:focus {
        @apply bg-brand-teal text-white;
    }
}

.label__number {
    @apply border border-brand-teal  text-center ml-2;
    border-radius: 50px;
    width: 27px;
    border-radius: 20px;
    line-height: 23px;
    text-align: center;
    padding: 2px 0 0 1px;
}

.has-dark-background {
    .label__text {
        @apply border-brand-sage text-brand-sage;
    }
}
