.hero-section-container{
    overflow-x: hidden;
}

.hero {
    @apply w-100;
}

.hero__grid {
    @apply flex flex-col items-center;
    gap: var(--space-xs-3xl);
    @apply w-100;
}

.hero__scroll-down-trigger {
    @apply text-brand-secondary inline-block rotate-90;
    order:1;
}

.hero__column {
    @apply w-full;
}

.hero__image {
    @apply mx-auto;
    order: 1;
    width: 66.66%;
    max-width: 470px;
}

.hero .social-links path {
    fill: var(--color-brand-teal);
}

.hero.style-1 .hero__text__wrapper .textEditor {
    margin-top: var(--space-l);
}

.style-1 .hero .hero__text__wrapper .label {
    margin-bottom: var(--space-m);
}

.style-1 .hero .hero__text__wrapper .heading-1 {
    margin-bottom: var(--space-l);
}

.hero-section-container.style-2 .heading-1 {
    font-size: var(--step-4);
}

.section-content-container.hero-content-container {
    padding-top: var(--space-xl-3xl);
    padding-bottom: var(--space-xl-3xl);
}

.hero__text__wrapper .social-links {
    margin-top: var(--space-l);
}

.hero .btn {
    border-color: var(--color-brand-forest);
}

.hero .btn:hover,
.hero .btn:focus {
    border-color: var(--color-brand-citrus);
}

/* style 2*/
.hero-section-container.style-2 .hero__grid {
    gap: var(--space-l);
}

.hero__style-2__content {
    @apply flex flex-wrap items-start;
    gap: var(--space-l);
    margin-top: var(--space-xl);
}


.hero__style-2__content__column__image {
    position:relative;
    margin-top: calc(var(--space-2xl-4xl) * -1 + var(--space-m));
    margin-left: calc(var(--space-xs-2xl) * -1);
    right: calc(var(--space-xs-2xl) * -1);
}

.hero__content {
    @apply  flex flex-col items-center justify-center h-full mx-auto  z-20;
}

.heading-hero {
    @apply font-sans;
}

.hero__buttons {
    @apply flex flex-wrap justify-center;
}

.hero__style-2__content__column .textEditor .text > p.lead-text {
    max-width: 23ch;
}

.hero__style-2__content__column:first-child {
    width: calc(80% - (var(--space-xs) / 2));
}


@screen md {
    .hero__style-2__content__column__image {
        margin-top: calc(var(--space-2xl-4xl) * -1 + var(--space-l));
        max-width: 70%;
    }
}

@screen lg {
    .style-1 .hero .hero__text {
        order: 2;
    }
    .hero__grid {
        @apply flex-row;
        gap: var(--space-xs-3xl);
    }
    .hero__column {
        width: 60%;
    }
    .hero__style-2__content__column{
        width: 70%;
    }
    .hero__style-2__content__column__image {
        @apply absolute;
        right: calc(var(--space-l) * -1);
        top: calc((var(--space-xs) * 2) + 58px);
        margin-top: 0;
        width: 40%;
        max-width: 684px;
    }
    .hero__scroll-down-trigger {
        order:0;
    }
}

@screen xl {
    .hero__style-2__content__column__image {
        right: calc(var(--space-2xl) * -1);
        margin-top: calc(var(--space-2xl) * -1);
        width: 45%;
    }
}

@media screen and (min-width: 1440px) {
    .hero__column {
        width: 58%;
    }
    .hero__style-2__content__column__image {
        margin-top: calc((var(--space-4xl) * -1) + var(--space-m));
        width: 50%;
    }

}

@media screen and (min-width: 1600px) {
    .hero__column {
        width: 54%;
    }
}

@media screen and (min-width: 1780px) {
    .hero-section-container.style-2 .heading-1 {
        font-size: var(--step-5);
    }
    .hero__column {
        width: 70%;
    }
}
