:root {
    /* Font Steps */
    /* @link https://utopia.fyi/type/calculator/?c=320,18,1.2,1440,21,1.333,5,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l */
    --step--2: clamp(0.74rem, calc(0.79rem + -0.06vw), 0.78rem);
    --step--1: clamp(0.94rem, calc(0.92rem + 0.07vw), 0.98rem);
    --step-0: clamp(1.13rem, calc(1.07rem + 0.27vw), 1.31rem);
    --step-1: clamp(1.35rem, calc(1.24rem + 0.57vw), 1.75rem);
    --step-2: clamp(1.62rem, calc(1.42rem + 1.02vw), 2.33rem);
    --step-3: clamp(1.94rem, calc(1.61rem + 1.66vw), 3.11rem);
    --step-4: clamp(2.33rem, calc(1.82rem + 2.59vw), 4.14rem);
    --step-5: clamp(2.80rem, calc(2.02rem + 3.89vw), 5.52rem);
    --label-text: calc(var(--step-1) * .35);
    --section-title: calc(var(--step-1) * .55);
}

@font-face {
    font-family: "Givonic";
    src: url('../../public/fonts/givonic-regular-webfont.woff2') format('woff2'),
         url('../../public/fonts/givonic-regular-webfont.woff') format('woff');
    font-display: swap;
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "Givonic";
    src: url('../../public/fonts/givonic-bold-webfont.woff2') format('woff2'),
         url('../../public/fonts/givonic-bold-webfont.woff') format('woff');
    font-display: swap;
    font-style: normal;
    font-weight: 700;
}

h1,
.heading-1 {
    font-size: var(--step-5);
    font-weight: 400;
    line-height: 1;
}

h2,
.heading-2 {
    font-size: var(--step-4);
    line-height: 1.067;
    font-weight: 400;
}

h3,
.heading-3 {
    font-size: var(--step-3);
    line-height: 1.125;
    font-weight: 400;
}

h4,
.heading-4 {
    font-size: var(--step-2);
    line-height: 1.2;
    font-weight: 400;
}

h5,
.heading-5 {
    font-size: var(--step-1);
    line-height: 1.333;
    font-weight: 400;
}
h6,
.p,
p,
ol li{
    font-size: var(--step-0);
    line-height: 1.778;
    font-weight: 400;
}

p.lead-text {
    line-height: 1.5;
    font-weight: 400;
    max-width: 80ch;
    font-size: var(--step-1);
}

small {
    font-size: var(--step--1);
    line-height: 1.5;
}

.micro {
    font-size: var(--step--2);
    line-height: 1.2;
}

.tagline{
    @apply text-brand-teal uppercase font-bold;
    font-size: var(--section-title);
    letter-spacing: 1.1;
    margin-bottom: var(--space-s);
}

.has-dark-background .tagline{
    color:#fff;
}

b,
strong {
    font-weight: 700;
}

h1, h2, h3, h4, h5, h6 {
    b,
    strong {
        font-weight: 400;
    }
}