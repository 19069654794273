.feature {
    @apply flex flex-col;
}

@screen md {
    .feature__features_list ul {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }

    .feature__main_description {
        @apply flex flex-wrap;
        gap: var(--space-l);
    }

    .feature__main_description {
        & > div {
            width: calc(50% - (var(--space-l) / 2));
        }
    }
}

.feature__features_list {
    margin-bottom: calc(var(--space-s) * -1);
}

.feature__features_list li {
    margin-bottom: var(--space-s);
    display: flex;
    font-size: var(--step-0);
    &::before {
        content: "";
        background-image: url(../../../svg/bullet.svg);
        background-repeat: no-repeat;
        width: 21px;
        height: 21px;
        padding-right: var(--space-l);
        top: 5px;
        position: relative;
    }
}

.feature__title,
.feature__main_description {
    margin-bottom: var(--space-xl);
}

.feature__cta {
    margin-top: var(--space-xl);
}