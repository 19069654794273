.pagination {
    @apply flex justify-center items-center mx-auto;

    & ul{
        flex-direction: column;
        gap: var(--space-l);
        padding-top: var(--space-2xl);
        padding-bottom: var(--space-2xl);
    }
    & [class^=pagination__]{
        font-size: var(--step-1);
    }

    & [class^=pagination__] a{
        @apply flex flex-nowrap items-center;
    }
    & [class^=pagination__] .pagination__chevron{
        top: -3px;
        position: relative;
    }

    & .pagination__prev .pagination__chevron{
        transform: rotate(180deg);
    }

    & .pagination__next .pagination__chevron{
        margin-left: var(--space-xs);
    }

    & .pagination__prev .pagination__chevron{
        margin-right: var(--space-xs);
    }

    & li {
        @apply flex items-center justify-center cursor-pointer transition-colors;
    }
}

@screen sm {
    .pagination {
        & [class^=pagination__]{
            font-size: var(--step-2);
        }
        & .pagination__next .pagination__chevron{
            margin-left: var(--space-m);
        }
    
        & .pagination__prev .pagination__chevron{
            margin-right: var(--space-m);
        }
    }
    .pagination ul {
        flex-direction: row;
    }
}

@screen lg {
    .pagination {
        & ul{
            gap: var(--space-2xl);
        }
        & [class^=pagination__]{
            font-size: var(--step-3);
        }
    }
}