.subscribe-content-container{
    padding: var(--space-xl) var(--space-s-xl);
    max-width: 1540px;
}

.subscribe-section-container{
    @apply bg-brand-forest;
}

.subscribe{
    @apply w-full;
     &  h4{
         @apply text-brand-sage;
         font-size: var(--step-2);
         max-width: 24ch;
     }
}

.subscribe__main_wrapper{
    @apply flex justify-start flex-wrap;
    flex-direction: column;
    & > div{
        width:100%;
    }
}

.subscribe__text-text-column {
    margin-bottom: var(--space-xl);
}

.subscribe__form {
    @apply w-full flex flex-wrap text-brand-sage;
    & > div{
        @apply w-full;
    }
    & > div:nth-child(n+2){
        margin-top: var(--space-xs);
    }
    & input[type=email],
    & input[type=text]{
        @apply w-full placeholder-brand-sage border-brand-sage border-2 text-brand-sage;
        border-radius: 500px;
        background:transparent;
        min-height:42px;
        font-size:18px;
        padding: 6px var(--space-xl) 4px;
    }
    .btn:hover,
    .btn:focus {
        background-color: rgba(255, 71, 19, 0.06);
    }
}

.subscribe__input-email_address{
    width:80%;
}

.subscribe__submit{
    @apply flex;
}
.subscribe__submit svg {
    width: 42px;
    height: 42px;
}

.subscribe__form__message {
    cursor: default;
    background-color: var(--color-brand-citrus);
    color: white;

    &:hover,
    &:focus {
        background-color: var(--color-brand-citrus);
        background-color: var(--color-brand-citrus);
    }
}

.subscribe .content__gdpr {
    margin-bottom: var(--space-s);
    display: flex;
    flex-wrap: wrap;
    gap: var(--space-2xs);
}

.subscribe .gdpr-mergeRow,
.subscribe .content__gdpr__unsub {
    width: 100%;
}

.subscribe .checkbox {
    display: inline-flex;
    align-items: flex-start;
}

.subscribe .checkbox input[type="checkbox"] {
    appearance: none;
    background-color: var(--color-brand-forest);
    margin: 0;
    padding: 0;
    font: inherit;
    color: currentColor;
    width: 12px;
    height: 12px;
    border: 1px solid currentColor;
    --tranform: calc(var(--space-s) * -1);
    transform: translateY(var(--transform));
    display: grid;
    place-content: center;
    flex-shrink: 0;
    position: relative;
    top: 1px;
}

.subscribe .checkbox input[type="checkbox"]::before {
    content: "";
    width: 6px;
    height: 6px;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--color-brand-citrus);
}

.subscribe .checkbox input[type="checkbox"]:checked::before {
    transform: scale(1);
}

.subscribe .checkbox {
    margin-left: 4px;
}

.subscribe .success-response {
    color: var(--color-brand-citrus);
    font-weight: 700;
}

.subscribe .checkbox span {
    margin-left: var(--space-2xs);
}

.subscribe .checkbox input {
    position: relative;
    top: -1px;
}

.subscribe div.mce_inline_error {
    display: flex;
    align-items: center;
    font-weight: 700;
    color: white;
    font-size: var(--step--2);
}

.subscribe div.mce_inline_error {
    margin-top: var(--space-2xs);
}

.subscribe div.mce_inline_error::before {
    content: "";
    background-image: url(../../../svg/bullet.svg);
    background-repeat: no-repeat;
    background-size: 10px;
    width: 10px;
    height: 10px;
    padding-right: var(--space-xs);
    position: relative;
    bottom: 1px;
}

@screen lg{
    .subscribe .checkbox {
        align-items: center;
    }
    .subscribe .checkbox input[type="checkbox"] {
        position: static;
    }
    .subscribe h4 {
        max-width: none;
    }
    .subscribe__text-text-column {
        margin-bottom: var(--space-l);
    }
    .subscribe__form > div:nth-child(n+1) {
        margin: 0 var(--space-2xs) 0 0;
    }
    .subscribe__form > div {
        width: initial;
    }

    .subscribe__form{
        max-width: unset;

        input {
            width: initial;
        }
    }
}

@screen xl {
    .subscribe__main_wrapper > div {
        width: auto;
    }
    .subscribe__text-text-column {
        min-width: 520px;
    }
    .subscribe .email {
        min-width: 375px;
    }
}

@screen xxl {
    .subscribe-content-container {
        max-width: 1680px;
    }
}
