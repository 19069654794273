.article__hero {
    @apply w-full text-center;
    & figure {
        @apply relative;
        margin: auto;
        border-radius: 21px;
        top: var(--space-xl);
        max-width: 880px;
        & img{
            border-radius: 21px;
        }
    }
    & figcaption{
        @apply absolute -bottom-10 right-0 text-brand-forest;
    }
}

.article__heading {
    position: relative;
}

.article__tags {
    @apply flex items-center max-w-none justify-center w-full  mx-auto flex-nowrap;
    gap: var(--space-m);
    margin: var(--space-s-m) 0;

    & ul {
        @apply flex flex-wrap;
        gap: var(--space-xs);
    }
}

.featured-articles-tags-content-container .article__tags{
    @apply justify-start;
}

.section-container.featured-articles-tags-section-container{
    @apply border-brand-forest border-b border-opacity-50;
    margin-bottom: var(--space-2xl-4xl);
}

.articles__hero__inner {
    @apply w-full flex items-center justify-between;
}

.articles__hero__inner__text h1 {
    font-size: var(--step-5);
    margin-bottom: var(--space-l);
}

.article__date {
    @apply border-b border-brand-forest border-opacity-50;
    font-size: var(--step--1);
    margin-bottom: var(--space-m);
    padding-top: var(--space-2xs);
    padding-bottom: calc(var(--space-2xs) - (var(--space-2xs)/3));
}
.article__title {
    display: block;
    margin-bottom: var(--space-2xs);
}
.article__summary {
    font-size: var(--step--1);
}

.articles__grid {
    @apply flex flex-wrap;
    max-width: 1380px;
    grid-column-gap: var(--space-l);
    grid-row-gap: var(--space-xl);
}
.articles__grid > div > a img {
    width: 100%;
}

.articles__grid h4 {
    font-size: var(--step-1);
}

.articles__grid img {
    border-radius: 21px;
}

.articles__hero__inner__motif {
    display: none;
    visibility: hidden;
}

.article__content {
    max-width: 754px;
    margin: auto;
}

@screen xs {
    .articles__grid {
        & > div {
            width: calc(50% - (var(--space-l) / 2));
        }
        & img {
            border-radius: 21px;
        }
    }
}

@screen sm {
    .articles__hero__inner {
        gap: var(--space-s-xl);
    }
    .articles__hero__inner__motif svg {
        width: 280px;
        max-width: 100%;
        height: auto;
    }
}

@screen md {
    .article__tags {
        gap: var(--space-l);

        & ul {
            gap: var(--space-s);
        }
    }

    .articles__hero__inner__motif {
        display: flex;
        visibility: visible;
        justify-content: flex-end;
    }
}

@screen lg {
    .articles__grid {
        & > div {
            width: calc(33.33% - ((var(--space-l) * 2) / 3));
        }
    }
    .article__heading h1 {
        margin: auto auto var(--space-l);
    }
    .articles__hero__inner__text {
        @apply w-3/5;
    }
}

@screen xl {
    .articles__grid h4 {
        font-size: var(--step-2);
    }
}