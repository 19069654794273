@import "./tailwind.css";

@import "../../node_modules/luminous-lightbox/dist/luminous-basic.css";
@import "./**/*.css";
@import "./defaults/*.css";
@import "../views/articles/*.css";
@import "../views/components/**/*.css";
@import "../views/snippets/**/*.css";
@import "./views/*.css";

body {
    @apply bg-brand-sage-accent text-brand-forest;
}

html, body {
    overflow-x: hidden;
	position: relative;
}

body.fullpage-nav-open {
    overflow-y: hidden;
    padding-right: 15px; /* Avoid width reflow */
}

.video-embed {
    aspect-ratio: 16 / 9;
    width: 100%;
}
