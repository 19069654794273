.contact {
    @apply mx-auto flex flex-wrap;
    max-width: 1920px;
    padding: clamp(2rem, 4vw, 6rem);
}

.contact-left {
    & .caption {
        @apply pt-8 pb-8;
    }
}

.contact-right {
    @apply flex flex-wrap w-full;
    & .contact-right-one {
        @apply w-1/2 text-center;
        & h3 {
            @apply text-brand-primary font-bold mb-5;
        }
        & ul {
            @apply leading-10;
        }
    }
    & .contact-right-two {
        @apply w-1/2 text-center;
        & h3 {
            @apply text-brand-primary font-bold mb-5;
        }
        & ul {
            @apply leading-10;
        }
        & ul li {
            @apply mb-5;
        }
    }
    & .contact-right-three {
        @apply w-full text-center my-10;
        & h3 {
            @apply text-brand-primary font-bold mb-5;
        }
    }
}

.contact-left-form {
    & h3 {
        @apply text-black mb-10 !important;
    }
    & .field {
        @apply py-3;
    }
    & input[type="text"],
    & input[type="tel"],
    & input[type="email"],
    & select {
        @apply bg-gray-500 !important;
    }
    & button {
        @apply my-3 w-full pt-6 pb-6;
    }
}

@screen md {
    .contact-right {
        & .contact-right-one,
        & .contact-right-two,
        & .contact-right-three {
            @apply w-1/3 my-0;
        }
    }
}

@screen lg {
    .contact {
        @apply flex-nowrap;
    }
    .contact-right {
        @apply justify-around;
        margin-top: 25px;
        & .contact-right-one,
        & .contact-right-two,
        & .contact-right-three {
            @apply flex flex-col w-full justify-center ;
        }
    }
}

@screen xl {
    .contact-left {
        @apply w-1/2;
    }
    .contact-right {
        @apply w-1/2;
        margin-top: 90px;
        padding-left: 169px;
        & .contact-right-one,
        & .contact-right-two {
            @apply w-1/2 text-left justify-start;
        }
        & .contact-right-three {
            @apply w-full text-left justify-start;
        }
        & .social-links {
            @apply justify-start;
        }
    }
}

@screen xxl {
    .contact-left {
        @apply w-40;
    }
}
