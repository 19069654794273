.testimonials__content {
    @apply flex align-top justify-between flex-col lg:flex-row;
    align-items: flex-start;
    gap: var(--space-l-2xl);
}

.testimonials__content p {
    color: var(--color-brand-teal);
}

.testimonial__text p {
    font-size: var(--step-1);
}

.testimonial__text .testimonial__author {
    font-size: var(--step-0);
}

.testimonial__image svg {
    max-width: 100%;
    height: auto;
}

@media screen and (max-width: 1023px) {
    .testimonial__image {
        width: 60%;
        margin-left: auto;
        margin-right: auto;
    }
}

@screen lg {
    .testimonial__image {
        display: flex;
        justify-content: flex-start;
    }
}