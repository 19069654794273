.footer {
    @apply w-full bg-white;
}

/* Main footer dark-background */

.footer__wrapper {
    @apply bg-brand-teal;
}

.footer__container {
    @apply mx-auto flex flex-wrap md:flex-nowrap;
    max-width: 1540px;
    padding: var(--space-xl) var(--space-s-xl);
}

.footer__links {
    @apply w-full flex flex-wrap justify-between;
    align-items: flex-start;
    color: #ffffff;
}

.footer__link {
    width: 100%;
    flex-grow: 2;
}

.footer__bottom-right .footer__logo {
    height: var(--space-2xl);
}

.footer__link:nth-child(1),
.footer__link:nth-child(2),
.footer__link:nth-child(3) {
    margin-bottom: var(--space-2xl);
}

.footer__top-left {
    display: flex;
    flex: 50%;
}

.footer__top-left a span {
    @apply pr-0 md:pr-2;
    font-size: var(--step-2);
    font-weight: normal;
    line-height: 1.2;
    & svg{
        @apply inline;
    }
}

.footer__top-right svg {
    display: inline;
    max-width: 288px;
    max-height: 84px;
}

.footer__top-right {
    min-width: 4rem;
    @apply flex justify-start md:justify-end;
}

.footer__bottom-left {
    @apply flex justify-start md:justify-end;
}

.footer__bottom-right {
    @apply flex justify-start md:justify-end;
}

.footer__container-nav {
    @apply border-t border-white border-opacity-50 mx-auto flex flex-wrap md:flex-nowrap;
    padding: var(--space-m) var(--space-s-xl);
    color: var(--color-white);
}

.footer__container-info {
    max-width: 1540px;
    padding: var(--space-m) var(--space-s-xl);
    color: #004750;
}

.footer__eco p {
    font-size: var(--step-0);
}

.footer__copyright {
    @apply flex flex-col;
}

.footer__copyright p {
    font-size: var(--step--1);
}

.footer__container-nav .anchor {
    text-decoration-color: var(--color-brand-secondary);
}

.footer__container-nav .anchor:hover,
.footer__container-nav .anchor:focus {
    text-decoration-color: var(--color-brand-teal);
}

.footer__top-left .anchor:hover,
.footer__top-left .anchor:focus {
    text-decoration-color: var(--color-white);
}

.footer__cta-text {
    max-width: 17ch;
    display: block;
}

/* Breakpoints */

@screen xs {
    .footer__top-left a span {
        font-size: var(--step-3);
    }
}

@screen sm {
    .footer__container-nav__inner {
        position: relative;
        text-align: right;
        width: 100%;
    
        .js-hamburger-menu {
            position: absolute;
            top: 5px;
            left: 0;
        }
    }
}

@screen md {
    .footer__links {
        column-gap: var(--space-l);
        position: relative;
    }

    .footer__link {
        width: 50%;
        flex-grow: 2;
    }

    .footer__top-right {
        width: 20%;
    }

    .footer__top-right .anchor {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    .footer__bottom-left,
    .footer__bottom-right {
        width: 45%;
        align-items: flex-end;
    }

    .footer__bottom-left {
        justify-content: flex-start;
        position: absolute;
        bottom: 0;
        left: 0;
    }

    .footer__bottom-right {
        justify-content: flex-end;
    }

    .footer__top-right svg {
        max-width: 395px;
    }

    .footer__link:nth-child(1),
    .footer__link:nth-child(2) {
        margin-bottom: var(--space-3xl);
    }

    .footer__link:nth-child(3) {
        margin-bottom: 0;
    }
}

@screen lg {
    .footer__top-right {
        width: 40%;
    }
    .footer__top-right svg {
        width: 395px;
    }
    .footer__copyright{
        flex-direction: row;
        justify-content: space-between;
    }
}

@screen xl {
    .footer__link:nth-child(1),
    .footer__link:nth-child(2) {
        margin-bottom: var(--space-4xl);
    }
}

@screen xxl {
    .footer__container {
        max-width: 1680px;
    }
    .footer__container-nav {
        max-width: 1680px;
    }
}