.image_aside__image_container {
    order: 0;
}

.image_aside__image_container img {
    width: 66.66%;
    max-width: 470px;
    @apply mx-auto;
}

@screen lg {
    .image_aside__image_container.align-right {
        order: 1;
    }

    .image_aside__image_container img {
        width: auto;
        max-width: 100%;
    }
}
